import axios from './axios'
// 百度
export async function baiduApi(newType) {
	let local_bd_vid = await window.localStorage.getItem('popularize') 
	if (local_bd_vid.indexOf('bd_vid') == -1)return
	let conversionTypes = [
		{
			"logidUrl": local_bd_vid,
			"newType": newType
		}
	]
	return axios({
		method: 'post',
		url: "/ocpc/uploadConvertData",
		data: { conversionTypes },
	})
}

// 积分排行榜
export function getRanking(){
	return axios({
		url:`/arena/ranking`,
		method: 'get',
	})
}
// 欧皇
export function winRanking(){
	return axios({
		url:`/arena/winRanking`,
		method: 'get',
	})
}
//注册
export function Register(mobile, password, name, verify, invite) {
	return axios({
		url: '/register',
		method: 'post',
		data: {
			mobile,
			password,
			name,
			verify,
			invite
		}
	})
}
//箱子数据及物品数据
export function Box_detail(id) {
	return axios({
		url: '/box/detail',
		params: {
			id
		}
	})
}

export function set_inviter(invite_code) {
	return axios({
		url: 'set_inviter',
		method: 'post',
		data: {
			invite_code
		}
	})
}
//发送邮箱验证码
export function PostEmail(email, usage) {
	return axios({
		url: '/send_email',
		method: 'post',
		data: {
			email,
			usage
		}
	})
}
//发送手机验证码
export function PostSendSms(mobile, is_use) {
	return axios({
		url: '/send_sms',
		method: 'post',
		data: {
			mobile,
			is_use
		}
	})
}

//登录
export function SignIn(mobile, password, verify = '') {
	if (verify == '') {
		return axios({
			url: '/login',
			method: 'post',
			data: {
				mobile, password
			}
		})
	} else {
		return axios({
			url: '/sms_login',
			method: 'post',
			data: {
				mobile, verify
			}
		})

	}

}

//个人信息
export function PersonalInfo() {
	return axios({
		url: '/me'
	})
}


//设置steam连接
export function SteamUrl(steam_url) {
	return axios({
		url: '/set_steam_url',
		method: 'post',
		data: {
			steam_url
		}
	})
}


//仓库
export function Storage(page, status) {
	return axios({
		url: '/storage',
		params: {
			page,
			status
		}
	})
}


//提取
export function Extract(data) {
	return axios({
		url: '/extract',
		method: 'post',
		data: {
			data
		}
	})
}

//回收
export function Cash(data) {
	return axios({
		url: '/cash',
		method: 'post',
		data: {
			data
		}
	})
}

//赠送
export function Give(id, invite_code) {
	return axios({
		url: '/give',
		method: 'post',
		data: {
			id,
			invite_code
		}
	})
}


// 金豆列表
export function Beans() {
	return axios({
		url: '/beans'
	})
}

//充值接口
export function Recharge(id, pay_way) {
	return axios({
		url: '/recharge',
		method: 'post',
		data: {
			id,
			pay_way
		}
	})
}

//充值记录
export function Recharge_record(page) {
	return axios({
		url: '/recharge_record',
		params: {
			page
		}
	})
}

//订单详情
export function BeansDetail(code) {
	return axios({
		url: '/bean/detail',
		params: {
			code
		}
	})
}


//创建房间
export function RoomSave(name, end_time, people_number, box_record_ids, describe, password, min_recharge, me_inviter) {
	return axios({
		url: 'room/save',
		method: 'post',
		data: {
			name,
			end_time,
			people_number,
			box_record_ids,
			describe,
			password,
			min_recharge,
			me_inviter
		}
	})
}
//合作推广
export function Promote() {
	return axios({
		url: '/promote'
	})
}

//下线
export function Be_inviter(page) {
	return axios({
		url: '/be_inviter',
		params: {
			page
		}
	})
}

//开箱记录
export function UnpackingRecord(id) {
	return axios({
		url: '/openhistory',
		params: {
			id
		}
	})
}

//热门宝箱
export function GetHotBox() {
	return axios({
		url: '/hotbox'
	})
}


// Roll房列表
export function Rooms(page, status) {
	return axios({
		url: '/rooms',
		params: {
			page,
			status
		}
	})
}

//Roll房详情
export function RoomDetail(id) {
	return axios({
		url: '/room/detail',
		params: {
			id
		}
	})
}

//加入Roll房
export function RoomJoin(id, password) {
	return axios({
		url: '/room/join',
		method: 'post',
		data: {
			id,
			password
		}
	})
}


//参加房间记录
export function UserRooms(page) {
	return axios({
		url: '/user/rooms',
		params: {
			page
		}
	})
}

//首冲奖励
export function BeansFirst() {
	return axios({
		url: '/beans/first'
	})
}
//累计充值
export function BeansTotal() {
	return axios({
		url: '/beans/total'
	})
}
//修改验证码
export function Set_invite_code(code) {
	return axios({
		url: '/set_invite_code',
		method: 'post',
		data: {
			code
		}
	})
}

//头像上传
export function base64(data) {
	return axios({
		url: '/image/base64',
		method: 'post',
		data: {
			data
		}
	})
}

//头像更新
export function Set_avatar(path) {
	return axios({
		url: '/set_avatar',
		method: 'post',
		data: {
			path
		}
	})
}

//装备类型
export function luckyType() {
	return axios({
		url: '/lucky/type'
	})
}

//装备列表
export function luckyList(type_id) {
	return axios({
		url: 'lucky/list',
		params: {
			type_id
		}
	})
}
//装备搜索
export function luckySo(name) {
	return axios({
		url: '/lucky/so',
		params: {
			name
		}
	})
}

//修改密码
export function Reset_password(mobile, password, verify) {
	return axios({
		url: '/reset_password',
		method: 'post',
		data: {
			mobile,
			password,
			verify
		}
	})
}

//站点信息
export function Info() {
	return axios({
		url: '/info'
	})
}

//进入房间盲盒频道
export function Blind_box_channel(client_id) {
	return {}
	return axios({
		url: '/arena/join-channel',
		method: 'post',
		data: {
			client_id
		}
	})
}

//卡密支付
export function Card_recharge(card) {
	return axios({
		url: '/card-recharge',
		method: 'post',
		data: {
			card
		}
	})
}

//更改用户名称
export function Set_Name(name) {
	return axios({
		url: '/set_name',
		method: 'post',
		data: {
			name
		}
	})
}

//创建对战
export function Arena_Create(user_num, box) {
	return {}
	return axios({
		url: '/arena/create',
		method: 'post',
		data: {
			user_num,
			box
		}
	})
}

//宝箱列表(盲盒对战)
export function Arena_Box_List() {
	return {}
	return axios({
		url: '/arena/box-list',
	})
}

//对战列表
export function Arena_List(page) {
	return {}
	return axios({
		url: '/arena/list',
		params: {
			page
		}
	})
}

//对战详情
export function Arena_Detail(game_arena_id) {
	return {}
	return axios({
		url: '/arena/detail',
		params: {
			game_arena_id
		}
	})
}

//加入房间
export function Arena_Join(game_arena_id, seat) {
	return {}
	return axios({
		url: '/arena/join',
		method: 'post',
		data: {
			game_arena_id,
			seat
		}
	})
}

//历史记录
export function Arena_History(page) {
	return {}
	return axios({
		url: '/arena/history',
		params: {
			page
		}
	})
}

//我的历史记录
export function Arena_Me_History(page) {
	return {}
	return axios({
		url: '/arena/me-history',
		params: {
			page
		}
	})
}

//夺宝房间列表
export function Snatch(page, status) {
	return axios({
		url: '/snatch',
		params: {
			page,
			status
		}
	})
}

//加入夺宝房间
export function Snatch_Join(id, num) {
	return axios({
		url: '/snatch/join',
		method: 'post',
		data: {
			id,
			num
		}
	})
}

//夺宝详情
export function Snatch_Detail(id) {
	return axios({
		url: '/snatch/detail',
		params: {
			id
		}
	})
}

//饰品列表
export function GetShop(min_integral, max_integral, sort, page) {
	return axios({
		url: '/shop',
		params: {
			min_integral,
			max_integral,
			sort,
			page
		}
	})
}

//饰品购买
export function Shop_Buy(id, num) {
	return axios({
		url: '/shop/exchange',
		method: 'post',
		data: {
			id,
			num
		}
	})
}

//福利宝箱列表
export function Welfare() {
	return axios({
		url: '/welfare'
	})
}


//福利宝箱开箱
export function WelfareOpen(id, cdk) {
	return axios({
		url: '/welfare/open',
		method: 'post',
		data: {
			id,
			cdk
		}
	})
}

//装备查询
export function AwardGet(id) {
	return axios({
		url: '/award/get',
		params: {
			id
		}
	})
}

//幸运开箱(开箱)
export function LuckyOpen(id, percent) {
	return axios({
		url: '/lucky/open',
		method: 'post',
		data: {
			id,
			percent
		}
	})
}

//回收
export function cash(data) {
	return axios({
		url: '/cash',
		method: 'post',
		data: {
			data
		}
	})
}

//宝箱详情
export function BoxDetail(id) {
	return axios({
		url: '/box/detail',
		params: {
			id
		}
	})
}

//开箱
export function Open(id, num) {
	return axios({
		url: '/open',
		method: 'post',
		data: {
			id,
			num
		}
	})
}

//红包
export function Red(id, num) {
	return axios({
		url: '/red'
	})
}

//开红包
export function RedOpen(type, id, key) {
	return axios({
		url: '/red/open',
		method: 'post',
		data: {
			type,
			id,
			key
		}
	})
}

//批量赠送
export function AGive(data, invite_code) {
	return axios({
		url: '/give',
		method: 'post',
		data: {
			data,
			invite_code
		}
	})
}

//装备合成
export function Synthesis(data, out_id) {
	return axios({
		url: '/synthesis',
		method: 'post',
		data: {
			data,
			out_id
		}
	})
}

//合成装备列表
export function Synthesis_list(page) {
	return axios({
		url: '/synthesis/list',
		params: {
			page
		}
	})
}

// vip收支明细
export function vipMe(page) {
	return axios({
		url: '/vip/me?page=' + page,
	})
}

// vip 列表
export function getVipLists(page) {
	return axios({
		url: '/vip/list',
	})
}

// 审核中的roll列表
export function rollPending(page) {
	return axios({
		url: '/room/pending',
	})
}

