<template>
	<div id="app">
		<keep-alive>
			<router-view />
		</keep-alive>
	</div>
</template>

<script>

	import {
		mapActions
	} from 'vuex'
	import {
		Blind_box_channel
	} from '@/network/api.js'
	export default {
		name: '',
		data() {
			return {}
		},

		mounted(){
				this.bd_vid()
				
		},
		created() {

			if (this._isMobile()) {
				 window.location.href = '//m.gkdcsgo.com'
			}
			this.$socket.Event(e => {
				sessionStorage.setItem('C_id', e)
				this.PostBlind_box_channel(e)
			}).Start()

			this.$socket.Add('joinGroup', this.JoinGroup)
			this.$socket.Add('endGroup', this.AddEndGroup)
		},

		methods: {
		 bd_vid() {
			// 百度  这个是推广进来之后需要保存的数据  都在这保存
			if (window.location.href.indexOf('bd_vid') == -1) return
			window.localStorage.setItem('popularize', window.location.href)
			// 如果query对象不等于空的时候 在看看里面是否有bd_vid这个值  之后有的话保存起来 没有的话直接不做操作
		},
			//判断访问设备
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag
			},

			PostBlind_box_channel(e) {
				Blind_box_channel(e).then(res => {})
			},

			//加入房间
			JoinGroup(data) {
				this.AddInformation(data)
			},

			//对战结束
			AddEndGroup(data) {
				this.GetJieShuVsData(data)
			},

			...mapActions([
				'AddInformation',
				'GetJieShuVsData'
			])
		}
	}
</script>

<style lang="scss">
.el-drawer__body{
	background-color: #20222b !important
}
.bg-main{
	background-image: linear-gradient(90deg, #ECB105, rgb(0 0 0 / 0%)), linear-gradient(#EC7205, rgb(0 0 0 / 0%));
}

		@import "./styles/bootstrap.css";

.lv1{
	background: url(./assets/images/NavHeader/LV1.png) no-repeat center;
	background-size: 100% 100%;
}
.lv2{
	background: url(./assets/images/NavHeader/LV2.png) no-repeat center;
	background-size: 100% 100%;
}
.lv3{
	background: url(./assets/images/NavHeader/LV3.png) no-repeat center;
	background-size: 100% 100%;
}
.lv4{
	background: url(./assets/images/NavHeader/LV4.png) no-repeat center;
	background-size: 100% 100%;
}
.lv5{
	background: url(./assets/images/NavHeader/LV5.png) no-repeat center;
	background-size: 100% 100%;
}

.el-slider__bar{
	background-color:#ECB105!important;
}
.el-slider__runway{
	background-color:#7581EB!important;
	border-radius: 0 !important;
}
.el-slider__button{
	background-color:#FFFFFF!important;
	width: 6px!important;
	height: 20px!important;
	border-radius: 0!important;
	border:none!important;
}

	* {
		margin: 0;
		padding: 0;
		background: url(/iconfont.svg);
	}

	ul,
	ol {
		list-style: none;
	}



	a {
		text-decoration: none;
	}

	.van-overlay {
		z-index: 100 !important;
	}

	body {
		background-color: #191919;
		color: #fff;
		// font-family: "SansSerif";
		font-family: "Times New Roman";
	}

	.Title-bg{
		width: 20rem;
		/*border: 1px solid #191b31;*/
		/*background-color: #131426;*/
		margin-left: -3rem;
		padding: .1rem;
	}
.Title-title{
	position: relative;
	&::before{
		content: "";
		bottom: -.2rem;
		left: 37.5%;
		position: absolute;
		width: 3rem;
		height: 50px;
		/*background-image: url("./assets/images/OpenBox/title.png");*/
		background-size: 100% 100%;
	}
}
	.Title {
		text-align: center;

		p {
			display: inline-block;
			margin: 0 auto;
			color: #fff;
			font-size: 24px;
			font-weight: 700;
			line-height: 1;
			text-transform: uppercase;
			/*text-shadow: 0 0 15px hsla(0, 0%, 100%, .5);*/
			padding: 20px 56px;
			// clip-path: polygon(13px 0, calc(100% - 13px) 0, 100% 50%, calc(100% - 13px) 100%, 13px 100%, 0 50%);
			position: relative;

			&::after {
				display: block;
				content: "";
				position: absolute;
				top: 6px;
				left: -60px;
				height: 80%;
				width: 100px;
				background: url(./assets/images/PublicImg/left.png) no-repeat center;
				background-size: contain;
			}



			&::before {
				display: block;
				content: "";
				position: absolute;
				top: 6px;
				right: -60px;
				height: 80%;
				width: 100px;
				background: url(./assets/images/PublicImg/left.png) no-repeat center;
				background-size: contain;
				transform: rotate(180deg);
			}
		}
	}

	@keyframes zhuan {
			0%{
				transform:rotate(0deg);
			}
			100%{
				transform:rotate(360deg);
			}
		}
</style>
